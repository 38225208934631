@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
  font-family: "Bitter-OFL";
  src: url('../fonts/Bitter-OFL/Bitter-Regular.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Korolev";
  src: url('../fonts/Korolev-Condensed-W04-Medium.ttf');
  font-weight: 500;
  font-style: medium;
}
