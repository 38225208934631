
body{
    background-color: rgb(250, 250, 250);
}


body{
      font-family: var(--font-bitter);

    font-weight: 300;

}

p a{
 
text-decoration: underline;
font-weight: 500;
}

.borderpart{

box-sizing: border-box;
    border-bottom: 1px solid #e5e7eb;
padding-bottom:15px;

    }

    .partenaires img{

max-height:120px;
width:auto;
 margin: 0 auto; 

    }
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.content-light{
      font-family: var(--font-bitter);
font-style: light;
    font-weight: 100;

}

.image-container {
  max-height: 300px;
  overflow: hidden;
  display: inline-block;
}

.titre{
          font-family: var(--font-korolev);

    font-weight: 500;


}


.prose.prose-lg img{
  width:100%!important;
}

.imgbackground{
z-index:0;
opacity:.4;
}

.centerinblock{
  position:absolute;


}

.gray-green{
    background-color: #d3dbdd;
}

.lavande{
    background-color: #dff2fd;
}

.lavande-opacity{
    background-color: #d3dbdd;
    opacity:100;
}

.jaune-pub{
    background-color: #f4efe9;
}

.leading-15{
line-height:5rem;}
.vh60{
max-height:40vh;
overflow:hidden;
}

.bottom-menu{
bottom:0px;
}



.gray-green-light{
    background-color: #e9f1f4;
}



.orange-500{
    background-color: rgb(249, 115, 22);
}

.orange-title{
    color: rgb(249, 115, 22);
}

.prose.prose-white{
    color: #FFFF;
}
.bleu{
    background-color: rgb(0, 120, 255);
}
.bleu-pastel{
    background-color: #659CB6;
}
.vertical-bar{
    background-color: rgb(0, 120, 255);
}

.green{
    background-color: #60c678;
}




.background-agenda{
  background-color:#dff2fd
}
